import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OffcanvasMenu from "../componets/Offcanvas";
import { BiLogoTelegram } from "react-icons/bi";

class SidebarMenu extends React.Component {
    render() {
        return (
            <Container fluid>
                <Row className="page flex-nowrap">
                    <Col className=" col-1 px-0 d-none d-lg-block sidebar">
                        <div className="d-flex flex-column align-items-center align-self-center align-items-sm-start px-3 pt-2 min-vh-100">
                            <a href="/" class="d-flex align-items-center align-self-center">
                                <span class="fs-5 brand">WorkerNet</span>
                            </a>
                            <OffcanvasMenu />
                            <div className="telegram fixed-bottom">
                                <a href="https://t.me/us_task" target="_blank" rel="noreferrer"><BiLogoTelegram className="icon-telegram" /></a>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default SidebarMenu;