import Carousel from 'react-bootstrap/Carousel';
import slide1 from '../assets/slide-1.jpg'
import slide2 from '../assets/slide-2.jpg'
import slide3 from '../assets/slide-3.jpg'

function CarouselAppsHeader() {
  return (
    <Carousel>
      <Carousel.Item>
        <img
          className="d-block w-100 header-slide-apps"
          src={slide1}
          alt="First slide"
        />
        <Carousel.Caption>
          <h3>Автоматизируйте</h3>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 header-slide-apps"
          src={slide2}
          alt="Second slide"
        />

        <Carousel.Caption>
          <h3>Управляйте</h3>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 header-slide-apps"
          src={slide3}
          alt="Third slide"
        />

        <Carousel.Caption>
          <h3>Отслеживайте</h3>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default CarouselAppsHeader;