import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class Contacs extends React.Component {

    render() {
        return (
            <>
                <Container fluid className="container-contacts">
                    <Container>
                        <Row className="row-row-contacts align-items-center">
                            <Row className="row-contacts">
                                <Col lg={4}>
                                    <h2>Контакты</h2>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col lg={6}>
                                            <p>Почта</p>
                                        </Col>
                                        <Col lg={6}>
                                            <p>info@apelsin-it.com</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6}>
                                            <p>Телефон</p>
                                        </Col>
                                        <Col lg={6}>
                                            <p>+7 (34345) 47-2-47</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6}>
                                            <p>Время работы</p>
                                        </Col>
                                        <Col lg={6}>
                                            <p>Пн-Пт 11:00-19:00 по МСК</p>
                                        </Col>
                                    </Row>
                                    
                                </Col>
                            </Row>
                            <Row className="row-contacts">
                                <Col lg={4}>
                                    <h2>Реквизиты</h2>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col lg={6}>
                                            <p>Наименование</p>
                                        </Col>
                                        <Col lg={6}>
                                            <p>ООО «Апельсин-Телеком»</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6}>
                                            <p>Юридический адрес</p>
                                        </Col>
                                        <Col lg={6}>
                                            <p>624740, Свердловская обл, г. Нижняя Салда, ул. Советская, д. 4, пом. 60</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6}>
                                            <p>ИНН</p>
                                        </Col>
                                        <Col lg={6}>
                                            <p>6623141603</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6}>
                                            <p>КПП</p>
                                        </Col>
                                        <Col lg={6}>
                                            <p>662301001</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6}>
                                            <p>ОГРН</p>
                                        </Col>
                                        <Col lg={6}>
                                            <p>1226600067506</p>
                                        </Col>
                                    </Row>
                                    
                                </Col>
                            </Row>
                        </Row>
                    </Container>    
                </Container>    
            </>
        )
    }
}

export default Contacs;