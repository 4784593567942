import React, { useState } from 'react';
import { Offcanvas, Nav, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import menu from '../assets/menu.png'

function OffcanvasMenu() {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const toggleShow = () => setShow(!show);

  return (
    <>
      <Button className="sidemenu" onClick={toggleShow}>
        <img
          src={menu}
          height="30"
          width="30"
          className=""
        />
      </Button>

      <Offcanvas className="sidenav" show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>WorkerNet</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav.Link as={Link} to="/" onClick={handleClose} ><span className='number-nav-link'>01</span>Главная</Nav.Link>
          <Nav.Link className="nav-link-apps" as={Link} to="/apps" onClick={handleClose} ><span className='number-nav-link'>02</span>Мобильное приложение<br /><span>Заявки монтажников</span></Nav.Link>
          <Nav.Link as={Link} to="/replaceus" onClick={handleClose} ><span className='number-nav-link'>03</span>Чем заменить UserSide</Nav.Link>
          <Nav.Link as={Link} to="/contacs" onClick={handleClose} ><span className='number-nav-link'>04</span>Контакты</Nav.Link>
          <Nav.Link as={Link} to="/wiki" onClick={handleClose} ><span className='number-nav-link'>05</span>База знаний</Nav.Link>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default OffcanvasMenu;
