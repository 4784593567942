import Carousel from 'react-bootstrap/Carousel';
import scrin1 from '../assets/scrin-1.png'
import scrin2 from '../assets/scrin-2.png'
import scrin3 from '../assets/scrin-3.png'
import scrin4 from '../assets/scrin-4.png'
import scrin5 from '../assets/scrin-5.png'
import scrin6 from '../assets/scrin-6.png'
import scrin7 from '../assets/scrin-7.png'
import scrin8 from '../assets/scrin-8.png'

function CarouselAppsScope() {
  return (
    <Carousel indicators={false} controls={false}>

      <Carousel.Item>
        <img
          className="d-block w-appsscope"
          src={scrin1}
        />
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-appsscope"
          src={scrin2}
        />
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-appsscope"
          src={scrin3}
        />
      </Carousel.Item>

          <Carousel.Item>
        <img
          className="d-block w-appsscope"
          src={scrin4}
        />
      </Carousel.Item>

            <Carousel.Item>
        <img
          className="d-block w-appsscope"
          src={scrin5}
        />
      </Carousel.Item>

          <Carousel.Item>
        <img
          className="d-block w-appsscope"
          src={scrin6}
        />
      </Carousel.Item>

            <Carousel.Item>
        <img
          className="d-block w-appsscope"
          src={scrin7}
        />
      </Carousel.Item>

            <Carousel.Item>
        <img
          className="d-block w-appsscope"
          src={scrin8}
        />
      </Carousel.Item>

    </Carousel>
  );
}

export default CarouselAppsScope;