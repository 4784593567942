import React, { useState } from 'react';
import { Navbar, Container, Nav, NavDropdown, Offcanvas } from 'react-bootstrap';
import { Routes, Route, Link } from "react-router-dom";

import Home from "../pages/Home";
import Apps from "../pages/Apps";
import ReplaceUs from "../pages/ReplaceUs";
import Contacs from "../pages/Contacs";
import Appdoc from '../pages/Appdoc';

const NavbarMenu = () => {
  const [colorChange, setColorchange] = useState(false);
  const changeNavbarColor = () => {
    if (window.scrollY >= 64) {
      setColorchange(true);
    }
    else {
      setColorchange(false);
    }
  };
  window.addEventListener('scroll', changeNavbarColor);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Navbar className={colorChange ? 'navmain colorChange d-lg-none' : 'navmain d-lg-none'} fixed="top" expand="lg">
        <Container>
          <Navbar.Brand href="/">WorkerNet
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleShow} />
          <Navbar.Offcanvas className="navmaincanvas" show={show} onHide={handleClose}>
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>WorkerNet</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="me-auto">
                <Nav.Link as={Link} to="/" onClick={handleClose} ><span className='number-nav-link-mobile'>01</span>Главная</Nav.Link>
                <Nav.Link className="nav-link-apps" as={Link} to="/apps" onClick={handleClose} ><span className='number-nav-link-mobile'>02</span>Мобильное приложение<br /><span>Заявки монтажников</span></Nav.Link>
                <Nav.Link as={Link} to="/contacs" onClick={handleClose} ><span className='number-nav-link-mobile'>03</span>Контакты</Nav.Link>
                <Nav.Link as={Link} to="/replaceus" onClick={handleClose} ><span className='number-nav-link-mobile'>04</span>Чем заменить UserSide</Nav.Link>
                <Nav.Link as={Link} to="/wiki" onClick={handleClose} ><span className='number-nav-link-mobile'>05</span>База знаний</Nav.Link>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/apps" element={<Apps />} />
        <Route path="/contacs" element={<Contacs />} />
        <Route path="/replaceus" element={<ReplaceUs />} />
        <Route path="/wiki" element={<Wiki />} />
        <Route path="/appdoc" element={<Appdoc />} />
        <Route path="/bill" element={<Bill />} />
        <Route path="/gpay" element={<Gpay />} />
      </Routes>
    </>
  )

  function Wiki() {
    window.location.replace('http://wiki.workernet.ru');
    return null;
  }

  function Bill() {
    window.location.replace('http://bill.workernet.ru');
    return null;
  }

  function Gpay() {
    window.location.replace('https://play.google.com/store/apps/details?id=ru.apelsin.us_task&hl=ru');
    return null;
  }

}

export default NavbarMenu;