import React from "react";
import Video from '../assets/video.mp4';

class VideoBg extends React.Component {

    render() {
        return (
            <div className="video-bg col">
                <div className="overlay"></div>
                <video src={Video} autoPlay loop muted />
                <div className="video-content">
                    <div className="container">
                        <div className="text-video">
                            <h1>Цифровые решения<br />для операторов связи</h1>
                            <div className="line"></div>
                            <p>Автоматизируем техническое обслуживание сетей, решаем задачи и проблемы менеджмента для сотрудников и эффективно выстраиваем 
                            связь абонент-оператор </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default VideoBg;