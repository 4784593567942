import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import VideoBg from "../componets/VideoBg";

class Home extends React.Component {
    render() {
        return (
            <>
                <Container fluid>
                    <Row className="flex-nowrap">
                        <VideoBg />
                    </Row>
                </Container>
            </>
        )
    }
}

export default Home;