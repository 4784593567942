import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import bgCardRequest from '../assets/bg-card-request.png'
import bgCardSwitching from '../assets/bg-card-switching.png'
import bgCardDevice from '../assets/bg-card-device.png'
import bgCardLocation from '../assets/bg-card-location.png'

function CardsApps() {
  return (
    <>

    <Row xs={1} sm={2} md={2} lg={2} className="g-4">
        <Col>
            <Card className="card-apps h-100">
            <Card.Img variant="top" src={bgCardRequest} />
            <Card.Body>
                <Card.Title>Обслуживание заявок</Card.Title>
                <Card.Text>
                Сотрудники будут постоянно иметь актуальную информацию по заданиям
                на которые они или их подразделение были назначены.
                </Card.Text>
            </Card.Body>
            </Card>
        </Col>

        <Col>
            <Card className="card-apps h-100">
            <Card.Img variant="top" src={bgCardSwitching} />
            <Card.Body>
                <Card.Title>Просмотр коммутации</Card.Title>
                <Card.Text>
                Возможность просмотра на карте узлов, муфт, опор, колодцев. Кроме этого, можно получить коммутацию каждого узла, муфты.
                </Card.Text>
            </Card.Body>
            </Card>
        </Col>

        <Col>
            <Card className="card-apps h-100">
            <Card.Img variant="top" src={bgCardDevice} />
            <Card.Body>
                <Card.Title>Оборудование</Card.Title>
                <Card.Text>
                Сотрудник может контролировать, какое оборудования на нем находится.
                </Card.Text>
            </Card.Body>
            </Card>
        </Col>

        <Col>
            <Card className="card-apps h-100">
            <Card.Img variant="top" src={bgCardLocation} />
            <Card.Body>
                <Card.Title>Местоположение</Card.Title>
                <Card.Text>
                Контролируйте движение по GPS в реальном времени, смотрите поездки.
                </Card.Text>
            </Card.Body>
            </Card>
        </Col>
    </Row>

    </>
  );
}

export default CardsApps;