import React from "react";
import NavbarMenu from "./componets/NavbarMenu";
import SidebarMenu from "./componets/SidebarMenu";
import ScrollToTop from "./componets/ScrollToTop";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <>
      <ScrollToTop />
      <SidebarMenu />
      <NavbarMenu />
    </>
  )
}

export default App;