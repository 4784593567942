import React from "react";
import Container from 'react-bootstrap/Container';
import Accordion from 'react-bootstrap/Accordion';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class ReplaceUs extends React.Component {

    render() {
        return (
            <>
                <Container fluid className="replace-us">
                    <Container>
                        <Row className="row-replace-us">
                            <Col>
                                <h1>Чем заменить UserSide </h1>
                                <p className="replace-us-p">Ещё в начале весны 2022 украинская компания UserSide объявила о своем официальном уходе из России. Однако спустя почти год многие российские пользователи всё ещё задаются вопросом, чем заменить UserSide.</p>
                                <Accordion className="replace-us-accordion" defaultActiveKey="0" flush>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>Что такое UserSide и почему она была так популярна?</Accordion.Header>
                                        <Accordion.Body>
                                            UserSide - это ERP-планирование ресурсов предприятия профиля «оператор связи».
                                            Функциональные возможности системы позволяют организовывать взаимную работу множества направлений на предприятии.
                                            Упор делается именно на комплексном управлении процессами/предоставлении информации на основе сразу всех доступных
                                            оперативных данных из сопутствующих источников (абоненты, инфраструктура, персонал, оборудование и т.п.).
                                            <br />
                                            <br />
                                            Весной 2022 года компания объявила о своём уходе с российского рынка. И многие пользователи из России и СНГ столкнулись с тем, что не могут продлить подписку.
                                            Также они наткнулись на сложности поддержки данных и прочие палки в колесах от зарубежной компании.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>Основные проблемы поиска аналогов</Accordion.Header>
                                        <Accordion.Body>
                                            Когда стало окончательно ясно, что вдохнуть вторую жизнь в привычный продукт от UserSide у российских пользователей не выйдет,
                                            остро стал вопрос о том, на какое программное обеспечение переходить.
                                            <br />
                                            <br />
                                            При этом был не столько важен поиск аналога, сколько возможность импортировать накопленную информацию и сохранить все задачи по
                                            действующим проектам.
                                            Вторым самым популярным страхом, парализующим уже бывших пользователей UserSide стало то, что они так долго привыкали к
                                            зарубежному интерфейсу, что просто не могли пересилить себя и отдать предпочтение чему-то новому.
                                            <br />
                                            <br />
                                            И, если первая проблема решалась возможностью перехода на WorkerNet — отечественный аналог украинского ПО.
                                            То для преодоления страха нового интерфейса у пользователей, разработчикам российского программного обеспечения пришлось немало
                                            потрудиться для повторения интерфейса.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>WorkerNet как аналог UserSide</Accordion.Header>
                                        <Accordion.Body>
                                            ERP WorkerNet — это сервис от российских разработчиков, ключевой задачей является оперативное отслеживание технических ошибок
                                            при поставке клиентской сети Интернет и их немедленное устранение текущими трудовыми ресурсами компании.
                                            <br />
                                            <br />
                                            Система позволяет распределять задачи по их важности, значимости, источникам и способам решения. Так, монтажный и технический
                                            отделы принимают работы по подключению нового абонента, устраняют аварии на локальных распределительных сетях и расширяют карту
                                            покрытия в соответствии с графиком, своевременно прокладывая новые кабельные линии.
                                            <br />
                                            <br />
                                            Если говорить об отличиях «WorkerNet» от UserSide, то они, конечно, есть, но только в лучшую сторону. Например, более современные
                                            технологии в основе, вложенность задач и проектов, наличие сквозных задач и многое другое. Также значительно переработаны и
                                            улучшены другие функции.
                                            <br />
                                            <br />
                                            Отечественная разработка имеет знакомый и интуитивно понятный дизайн и настройки. Если вы не смогли найти нужных вам параметров
                                            среди имеющихся, достаточно связаться с командой разработчиков, они оперативно реагируют на все обращения и постоянно работают
                                            над улучшением продукта.
                                            <br />
                                            <br />
                                            Самое существенное отличие, которое по совместительству является главным преимуществом представленного аналога Юзера — то, что
                                            продукт является отечественной разработкой, которая доступна для покупки из России.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>Импорт данных из UserSide</Accordion.Header>
                                        <Accordion.Body>
                                            Самый главный вопрос: возможен ли импорт информации из ЮС в «WorkerNet» без потери данных? Конечно возможен!
                                            <br />
                                            <br />
                                            Перенести можно информацию любого типа: закрытые и актуальные проекты, задачи, вложения к карточкам, аккаунты
                                            пользователей, заданные настройки и параметры, комментарии и многое другое.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </Col>
                        </Row>
                        <Row>

                        </Row>
                    </Container>
                </Container>
            </>
        )
    }
}

export default ReplaceUs;